const INITIAL_STATE = {
  notifications: [],
  loading: false,
  success: false,
  error: false,
  successMessage: null,
  errorMessage: null,
};

export const notification = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS_START": {
      return {
        ...state,
        loading: true,
      };
    }
    case "GET_NOTIFICATIONS_SUCCESS": {
      return {
        ...state,
        notifications: action.payload,
        loading: false,
        success: true,
      };
    }
    case "GET_NOTIFICATIONS_ERROR": {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case "REMOVE_NOTIFICATION_START": {
      return {
        ...state,
        loading: true,
      };
    }
    case "REMOVE_NOTIFICATION_SUCCESS": {
      return {
        ...state,
        loading: false,
        success: true,
        successMessage: action.payload.successMessage,
      };
    }
    case "REMOVE_NOTIFICATION_ERROR": {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    }

    default: {
      return state;
    }
  }
};
