import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import shipment from "./shipment/index";
import api from "./api/index";
import account from "./account/index";
import notification from "./notification/index";

const appReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  shipment: shipment,
  notification: notification,
  account: account,
  api: api,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_START") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
