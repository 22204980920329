import axios from 'axios';
import { CRUZ_DEL_SUR_TOKEN, API_BASE_URL } from '../constants';

// Get Status
export async function getStatus() {
  const token = localStorage.getItem(`${CRUZ_DEL_SUR_TOKEN}`);
  const config = {
    headers: {
      'x-access-token': token,
    },
  };
  if (!token) return;
  return await axios.get(`${API_BASE_URL}/status`, config);
}

export async function getStatusVtex() {
  const token = localStorage.getItem(`${CRUZ_DEL_SUR_TOKEN}`);
  const config = {
    headers: {
      'x-access-token': token,
    },
  };
  if (!token) return;
  return await axios.get(`${API_BASE_URL}/status-vtex`, config);
}

export default { getStatus, getStatusVtex };
