const INITIAL_STATE = {
  shipments: [],
  loading: false,
  success: false,
  error: false,
  successMessage: null,
  errorMessage: null,
};

export const shipment = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ORDERS_START": {
      return {
        ...state,
        shipments: action.payload,
        loading: true,
      };
    }
    case "GET_ORDERS_SUCCESS": {
      return {
        ...state,
        shipments: action.payload,
        loading: false,
        success: true,
      };
    }
    case "GET_ORDERS_ERROR": {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case "REMOVE_ORDER_START": {
      return {
        ...state,
        loading: true,
      };
    }
    case "REMOVE_ORDER_SUCCESS": {
      return {
        ...state,
        loading: false,
        success: true,
        successMessage: action.payload.successMessage,
      };
    }
    case "REMOVE_ORDER_ERROR": {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    }

    default: {
      return state;
    }
  }
};
