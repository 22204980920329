import React from "react";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";

const ApiStatusBar = (props) => {
  return (
    <div className="bookmark-wrapper col-sm-12 col-md-10" style={{paddingLeft: 0}} >
      <span className="pr-1">
        <span className="text-bold-600 pr-2">Cruz Del Sur</span>
        <Badge
          className="badge-xl"
          pill
          color={props.cruzDelSurStatus === "OK" ? "primary" : "danger"}
        >
          {props.cruzDelSurStatusLoading ? (
            <span className="font-small-3">Cargando...</span>
          ) : (
            <span className="font-small-3">
              {props.cruzDelSurStatus === "OK" ? "Activa" : "Inactiva"}
            </span>
          )}
        </Badge>
      </span>
      <span className="pl-1">
        <span className="text-bold-600 pr-2">VTEX</span>
        <Badge
          className="badge-xl"
          pill
          color={props.vtexStatus === "OK" ? "primary" : "danger"}
        >
          {props.vtexStatusLoading ? (
            <span className="font-small-3">Cargando...</span>
          ) : (
            <span className="font-small-3">
              {props.vtexStatus === "OK" ? "Activa" : "Inactiva"}
            </span>
          )}
        </Badge>
      </span>
      {props.vtexStatus !== "OK" || props.cruzDelSurStatus !== "OK" ? (
        <span className="pl-1">
          <Badge pill className="badge-xl" color="warning">
            <Icon.AlertCircle size={16} />{" "}
            <span className="font-small-3">
              Complete la información en{" "}
              <Link to="/connections">Conexiones</Link>
            </span>
          </Badge>
        </span>
      ) : null}
    </div>
  );
};

export default ApiStatusBar;
