const INITIAL_STATE = {
  cruzDelSurStatus: null,
  vtexStatus: null,
  cruzDelSurStatusLoading: true,
  vtexStatusLoading: true,
  cruzDelSurFulfilled: null,
  vtexFulfilled: null,
};

export const api = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_API_STATUS_START":
      return {
        ...state,
        cruzDelSurStatusLoading: action.payload.cruzDelSurStatusLoading,
        vtexStatusLoading: action.payload.vtexStatusLoading,
      };
    case "SET_API_STATUS":
      return {
        ...state,
        cruzDelSurStatus: action.payload.cruzDelSurStatus,
        vtexStatus: action.payload.vtexStatus,
        cruzDelSurStatusLoading: action.payload.cruzDelSurStatusLoading,
        vtexStatusLoading: action.payload.vtexStatusLoading,
        cruzDelSurFulfilled: action.payload.cruzDelSurFulfilled,
        vtexFulfilled: action.payload.vtexFulfilled,
      };
    case "SET_API_STATUS_ERROR":
      return {
        ...state,
        cruzDelSurStatus: action.payload.cruzDelSurStatus,
        vtexStatus: action.payload.vtexStatus,
        cruzDelSurStatusLoading: action.payload.cruzDelSurStatusLoading,
        vtexStatusLoading: action.payload.vtexStatusLoading,
      };
    case "SET_API_STATUS_VTEX_START":
      return {
        ...state,
        vtexStatusLoading: action.payload.vtexStatusLoading,
      };
    case "SET_API_STATUS_VTEX":
      return {
        ...state,
        vtexStatus: action.payload.vtexStatus,
        vtexStatusLoading: action.payload.vtexStatusLoading,
        vtexFulfilled: action.payload.vtexFulfilled,
      };
    case "SET_API_STATUS_VTEX_ERROR":
      return {
        ...state,
        vtexStatus: action.payload.vtexStatus,
        vtexStatusLoading: action.payload.vtexStatusLoading,
      };
    default: {
      return state;
    }
  }
};
