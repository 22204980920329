import React from "react";
import {
    UncontrolledDropdown,
    DropdownToggle,
} from "reactstrap";
import {history} from "../../../history";
import {connect} from "react-redux";
import * as AuthClient from "../../../clients/AuthClient";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";


class NavbarUser extends React.PureComponent {
    /* constructor(props) {
      super(props); */
    state = {
        navbarSearch: false,
        suggestions: [],
    };
    // }

    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch,
        });
    };

    handleName = (name) => {
        const defaultAbbr = "UD";
        let letters = null;
        if (name) {
            const slices = name.split(" ");
            if (typeof slices === Array && slices.length() > 1) {
                letters = `${slices[0][0]}${slices[1][0]}`;
            } else {
                letters = `${name.charAt(0)}${name.charAt(1)}`;
            }
            return letters.toUpperCase();
        } else {
            return defaultAbbr;
        }
    };

    render() {
        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">
                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                        <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600 text-uppercase" style={{marginLeft: 0}}>
                {this.props.account.businessName}
              </span>
                            <span className="user-status" style={{alignSelf: 'flex-start'}}>{this.props.account.role}</span>
                        </div>
                        <span data-tour="user">
              <Avatar
                  size="lg"
                  content={this.handleName(this.props.account.businessName)}
              />
            </span>
                    </DropdownToggle>
                </UncontrolledDropdown>
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        //account: state.account.account,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            AuthClient.logout()
                .then((response) => {
                    console.log("Logging out...");
                    if (response.data) {
                        dispatch({type: "LOGOUT_START"});
                        history.push("/logout");
                    }
                })
                .catch((error) => {
                    console.log("Error on logout");
                });
        },
    };
};

const NavbarUserConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavbarUser);
export default NavbarUserConnected;
