const INITIAL_STATE = {
  userRole: "admin",
  role: "MERCHANT",
  email: "",
  businessName: "",
  token: null,
};
export const login = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_START": {
      return { ...state, user: action.payload };
    }
    case "LOGIN_SUCCESS": {
      return { ...state, token: action.payload };
    }
    case "LOGIN_FAIL": {
      return { ...state, error: action.error };
    }
    case "SET_USER": {
      return { ...state, user: action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
