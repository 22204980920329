import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error("Error de conexión. Asegúrese que la API se encuentre activa.");
  }

  const { status, data } = error.response;
  // On get an internal sever error
  if (status === 401 && data.redirect === "login") {
    //console.log(data);
    // history.push("/logout");
  }

  if (status === 500) {
    toast.error("Error del servidor");
  }
});
