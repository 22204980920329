import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/dashboard",
  },
  {
    id: "orders",
    title: "Órdenes",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/orders/:status",
  },
  {
    id: "connections",
    title: "Conexiones",
    type: "item",
    icon: <Icon.Radio size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/connections",
  }
];

export default navigationConfig;
