import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import logger from "redux-logger";

import rootReducer from "../reducers/rootReducer";

// Set as false whether you want to active logger
const DEBUG = false;

const customMiddleWare = (store) => (next) => (action) => {
  if (DEBUG) {
    console.log("Middleware triggered:", action);
  }
  next(action);
};
const middlewares = [
  thunk,
  createDebounce(),
  DEBUG && logger,
  customMiddleWare,
].filter(Boolean);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
