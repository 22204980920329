const USER_LOGIN_INIT = "USER_LOGIN_INIT";
const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
const CRUZ_DEL_SUR_TOKEN = "cruzdelsur-x-access-token";
const CRUZ_DEL_SUR_EMAIL = "cruzdelsur-email";
const CRUZ_DEL_SUR_STORED_EMAIL = "cruzdelsur-user-email";

/** ROUTES */
const API_BASE_URL = "/api";
const AUTH_API_URL = "/api/auth";
const SHIPMENT_BASE_URL = "/api/shipment";
const NOTIFICATION_BASE_URL = "/api/notifications";
const ACCOUNT_API_URL = "/api/user";

const UNKNOWN = "desconocido";

const CRUZ_DEL_SUR_ORDER_STATUSES = [
  {
    name: "all",
    alias: "to-send",
    color: "primary",
    es: "TODOS",
  },
  {
    name: "POR ENVIAR",
    alias: "to-send",
    color: "secondary",
    es: "POR ENVIAR",
  },
  {
    name: "PROCESADO",
    alias: "process",
    color: "success",
    es: "PROCESADO",
  }/*,
  {
    name: "DESESTIMADO",
    alias: "cancelled",
    color: "warning",
    es: "DESESTIMADO",
  }*/
];

const VTEX_ORDER_STATUSES = [
  {
    name: "READY-FOR-HANDLING",
    color: "info",
    es: "PARA EMBALAR",
  },
  {
    name: "HANDLING",
    color: "info",
    es: "EN EMBALAJE",
  },
  {
    name: "CANCELED",
    color: "danger",
    es: "CANCELADO",
  },
  {
    name: "CANCEL",
    color: "danger",
    es: "CANCELADO",
  },
  {
    name: "INVOICED",
    color: "info",
    es: "FACTURADO",
  },
  {
    name: "WINDOW-TO-CANCEL",
    color: "info",
    es: "CANCELABLE",
  },
  {
    name: UNKNOWN.toUpperCase(),
    color: "secondary",
    es: "DESCONOCIDO",
  },
];
const SHIPMENT_TYPES = [
  {
    value: "REGULAR",
    label: "REGULAR",
    desc:
      "Se entrega durante todo el día de recibido el pedido.  De 9:00 a 21:00",
  },
  {
    value: "EXPRESS",
    label: "EXPRESS",
    desc:
      "Se entrega el mismo día de recibido el pedido. Máximo 4 horas después",
  },
  {
    value: "PROGRAMADO",
    label: "PROGRAMADO",
    desc: "Se entrega en una ventana horaria del día siguiente.",
  },
];

export {
  API_BASE_URL,
  AUTH_API_URL,
  SHIPMENT_BASE_URL,
  NOTIFICATION_BASE_URL,
  ACCOUNT_API_URL,
  USER_LOGIN_INIT,
  USER_LOGIN_FAIL,
  CRUZ_DEL_SUR_TOKEN,
  CRUZ_DEL_SUR_ORDER_STATUSES,
  VTEX_ORDER_STATUSES,
  SHIPMENT_TYPES,
  UNKNOWN,
  CRUZ_DEL_SUR_STORED_EMAIL,
  CRUZ_DEL_SUR_EMAIL
};
