import axios from 'axios';
import { AUTH_API_URL } from '../constants';

export async function logout() {
  return await axios.get(`${AUTH_API_URL}/logout`);
}

export async function login(data) {
  const config = {
    'Content-Type': 'application/json',
  };
  return await axios.post(`${AUTH_API_URL}/external/login`, data, config);
}

export default {
  Auth: {
    login,
    logout,
  },
};
