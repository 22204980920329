const INITIAL_STATE = {
  email: null,
  businessName: null,
  role: "MERCHANT",
  success: false,
  successMessage: null,
  error: false,
  errorMessage: null,
  loading: false,
  redirect: false,
};

export const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ACCOUNT_INFO_INIT":
      return {
        INITIAL_STATE,
      };
    case "SET_ACCOUNT_INFO_START":
      return {
        ...state,
        loading: true,
      };
    case "SET_ACCOUNT_INFO_SUCCESS":
      return {
        ...state,
        role: "MERCHANT",
        email: action.payload.email,
        businessName: action.payload.businessName,
        error: false,
        success: action.payload.success,
        successMessage: action.payload.successMessage,
        loading: false,
      };
    case "SET_ACCOUNT_INFO_ERROR":
      return {
        ...state,
        error: true,
        errorMessage: "Error al obtener informacion de la cuenta",
        loading: false,
      };
    case "RECOVERY_ACCOUNT_INFO_START":
      return {
        ...state,
        loading: true,
      };
    case "RECOVERY_ACCOUNT_INFO_SUCCESS":
      return {
        ...state,
        email: action.payload.email,
        error: false,
        success: action.payload.success,
        successMessage: action.payload.successMessage,
        loading: false,
      };
    case "RECOVERY_ACCOUNT_INFO_ERROR":
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    // Hash Validate Actions
    case "VALIDATE_HASH_ACCOUNT_INFO_START":
      return {
        ...state,
        loading: true,
      };
    case "VALIDATE_HASH_ACCOUNT_INFO_SUCCESS":
      return {
        ...state,
        success: action.payload.success,
        successMessage: action.payload.successMessage,
        loading: false,
      };
    case "VALIDATE_HASH_ACCOUNT_INFO_ERROR":
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case "RESTORE_ACCOUNT_INFO_START":
      return {
        ...state,
        loading: true,
      };
    case "RESTORE_ACCOUNT_INFO_SUCCESS":
      return {
        ...state,
        loading: false,
        redirect: true,
        success: action.payload.success,
        successMessage: action.payload.successMessage,
      };
    case "RESTORE_ACCOUNT_INFO_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
      };
    default: {
      return state;
    }
  }
};
