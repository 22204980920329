import * as ApiClient from "../../../clients/ApiClient";

const setApiStatusActionThunk = () => {
    return async (dispatch) => {
        console.log(`Inside dispatch`);
        dispatch({
            type: "SET_API_STATUS_START",
            payload: {cruzDelSurStatusLoading: true, vtexStatusLoading: true},
        });
        return await ApiClient.getStatus()
            .then((response) => {
                if (response) {
                    if (response.status === 200) {
                        dispatch({
                            type: "SET_API_STATUS",
                            payload: {
                                ...response.data,
                                cruzDelSurStatusLoading: false,
                                vtexStatusLoading: false,
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: "SET_API_STATUS_ERROR",
                    payload: {
                        cruzDelSurStatusLoading: false,
                        vtexStatusLoading: false,
                    },
                });
            });
    };
};

const setApiStatusVtexActionThunk = () => {
    return async (dispatch) => {
        console.log(`Inside dispatch`);
        dispatch({
            type: "SET_API_STATUS_VTEX_START",
            payload: {vtexStatusLoading: true},
        });
        return await ApiClient.getStatusVtex()
            .then((response) => {
                if (response) {
                    if (response.status === 200) {
                        dispatch({
                            type: "SET_API_STATUS_VTEX",
                            payload: {
                                ...response.data,
                                vtexStatusLoading: false,
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: "SET_API_STATUS_VTEX_ERROR",
                    payload: {
                        vtexStatusLoading: false,
                    },
                });
            });
    };
};

export {setApiStatusActionThunk, setApiStatusVtexActionThunk};
